import { Field, useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import SelectSearch from 'react-select';

const CustomSelect = ({
	customOnChange,
	field,
	form,
	is_multi = false,
	options,
}) => {
	const getValue = () => {
		if (options) {
			return is_multi
				? options.filter((option) => field.value.indexOf(option.value) >= 0)
				: options.find((option) => option.value === field.value);
		} else {
			return is_multi ? [] : '';
		}
	};

	const onChange = (option) => {
		if (customOnChange) customOnChange({ form, ...option });
		form.setFieldValue(
			field.name,
			is_multi ? option.map((item) => item.value) : option.value
		);
	};

	return (
		<SelectSearch
			onChange={onChange}
			isMulti={is_multi}
			name={field.name}
			options={options}
			value={getValue()}
		/>
	);
};

const Select = ({ ...props }) => {
	const [field, meta] = useField(props);

	return (
		<div className="mb-3">
			<label className="form-label" htmlFor={props.id}>
				{props.placeholder}
			</label>

			<Field component={CustomSelect} {...field} {...props} />
			{meta.error && meta.touched ? (
				<div className="invalid-feedback d-block">{meta.error}</div>
			) : null}
		</div>
	);
};

CustomSelect.propTypes = {
	customOnChange: PropTypes.func,
	field: PropTypes.object,
	form: PropTypes.object,
	is_multi: PropTypes.bool,
	placeholder: PropTypes.string,
	options: PropTypes.array,
};

CustomSelect.defaultProps = {
	customOnChange: null,
};

Select.propTypes = {
	children: PropTypes.array,
	id: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
};

export default Select;
