import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const Checkbox = ({ children, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<div className="form-check mb-3">
			<input
				className={`form-check-input ${
					meta.error && meta.touched ? 'is-invalid' : ''
				}`}
				type="checkbox"
				{...field}
				{...props}
			/>
			<label className="form-check-label small" htmlFor={props.id}>
				{children}
			</label>

			{meta.error && meta.touched ? (
				<div className="invalid-feedback">{meta.error}</div>
			) : null}
		</div>
	);
};

Checkbox.propTypes = {
	children: PropTypes.string,
	id: PropTypes.string,
	placeholder: PropTypes.string,
};

export default Checkbox;
