import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';

const PhoneNumber = ({ ...props }) => {
	const [field, meta, helpers] = useField(props);

	function formatPhoneNumber(isValid, newNumber, countryData, fullNumber) {
		if (isValid && fullNumber) {
			return fullNumber.replace(/(\s|-)/g, '');
		}
		return 'invalid_phone_number';
	}

	return (
		<div className="mb-3">
			<label className="form-label" htmlFor={props.id}>
				{props.placeholder}
			</label>

			<IntlTelInput
				containerClassName="intl-tel-input"
				fieldId={field.name}
				fieldName={field.name}
				format={true}
				inputClassName={`form-control ${
					meta.error && meta.touched ? 'is-invalid' : ''
				}`}
				onPhoneNumberBlur={() => {
					helpers.setTouched(true);
				}}
				onPhoneNumberChange={(...args) => {
					helpers.setValue(formatPhoneNumber(...args));
				}}
				preferredCountries={['nl']}
			/>

			{meta.error && meta.touched ? (
				<div className="invalid-feedback d-block">{meta.error}</div>
			) : null}
		</div>
	);
};

PhoneNumber.propTypes = {
	children: PropTypes.array,
	id: PropTypes.string,
	placeholder: PropTypes.string,
};

export default PhoneNumber;
