import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Loader from '../framework/loader';

const Input = ({ name }) => {
	const { isSubmitting, isValidating } = useFormikContext();

	return (
		<div className="col-12">
			{!isSubmitting && !isValidating && (
				<button
					className="btn btn-primary text-light"
					disabled={isSubmitting || isValidating}
					onMouseDown={(event) => {
						event.preventDefault();
					}}
					type="submit"
				>
					{name}
				</button>
			)}

			{(isSubmitting || isValidating) && <Loader />}
		</div>
	);
};

Input.propTypes = {
	name: PropTypes.string,
};

export default Input;
