import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import Checkbox from '../components/form/checkbox';
import Disclaimer from '../components/framework/disclaimer';
import Form from '../components/form';
import Framework from '../components/framework';
import Input from '../components/form/input';
import PhoneNumber from '../components/form/phone_number';
import Select from '../components/form/select';
import Seo from '../components/framework/seo';
import Submit from '../components/form/submit';
import withLocation from '../middleware/withLocation';

const Register = ({ data, location, search }) => {
	const [products, setProducts] = useState([]);
	const [result, setResult] = useState('new');
	const [tracking, setTracking] = useState(false);
	const { t } = useTranslation('register');

	const dateOfBirthRegExp =
		/^((?:19|20)[0-9][0-9])-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
	const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
	const validationSchema = Yup.object().shape({
		agreement_accepted: Yup.bool().oneOf([true], t('t1')).required(t('t1')),
		citizenship: Yup.string().required(t('t1')),
		date_of_birth: Yup.string()
			.matches(dateOfBirthRegExp, t('t2'))
			.required(t('t1')),
		email: Yup.string().email(t('t3')).required(t('t1')),
		firstname: Yup.string().required(t('t1')),
		lastname: Yup.string().required(t('t1')),
		location: Yup.string().required(t('t1')),
		passport_number: Yup.string().required(t('t1')),
		personal_number: Yup.string(),
		phone_number: Yup.string().matches(phoneRegExp, t('t4')).required(t('t1')),
		product: Yup.mixed().oneOf(['antigen', 'pcr']).required(t('t1')),
	});

	const citizenships = data.allCitizenship.edges.map((value) => {
		return { label: value.node.name, value: value.node.id };
	});

	const locations = data.allLocation.edges
		.filter((value) => {
			if (value.node.partner.id != 'zample-nl') {
				return false;
			}
			return true;
		})
		.map((value) => {
			return { label: value.node.name, value: value.node.id };
		});

	const product_map = {
		antigen: 'Antigen-test',
		pcr: 'PCR-test',
	};

	const newForm = () => {
		history.pushState(null, null, '/register/');
		setResult('new');
	};

	const handleChangeLocation = ({ value }) => {
		setProducts([]);

		for (let location_data of data.allLocation.edges) {
			if (location_data.node.id != value) continue;

			let tmp = {};
			for (let product_data of location_data.node.products) {
				tmp[product_data.type] = product_data.type;
			}

			let new_product_list = [];
			for (let key in tmp) {
				new_product_list.push({ label: product_map[key], value: key });
			}

			setProducts(new_product_list);
		}
	};

	const handleSubmit = async (
		values,
		{ isSubmitting, resetForm, setSubmitting }
	) => {
		const test = false;
		if (isSubmitting) return;

		const options = {
			body: JSON.stringify(values),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			method: 'POST',
		};

		const url =
			'https://europe-west1-testmottagningen.cloudfunctions.net/booking-register';
		try {
			if (test) {
				console.log(values);
				await new Promise((r) => setTimeout(r, 5000));
				setResult('sent');
			} else {
				const response = await fetch(url, options);
				const result = await response.json();

				if (result && result.error) {
					setResult('error');
				} else if (result && result.data && result.data.id) {
					history.pushState(null, null, '/register/?step=done');
					setResult('sent');
				}
			}

			setProducts([]);
			resetForm();
			setSubmitting(false);
		} catch (error) {
			setResult('error');
			setSubmitting(false);
		}

		const element = document.getElementById('scrollto');
		element.scrollIntoView();
	};

	useEffect(async () => {
		if (search && (search.step == 'booking' || search.step == 'sent')) {
			setResult(search.step);
		}
		if (search && search.tracking) {
			setTracking(search.tracking);
		}
	}, [search]);

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<div className="container mt-3 pt-lg-5">
				{result === 'booking' && (
					<div className="alert alert-info my-3" role="alert">
						<p className="h6">{t('t26')}</p>
						{t('t27')}
					</div>
				)}

				<h1
					className="h2"
					id="scrollto"
					dangerouslySetInnerHTML={{ __html: t('t5') }}
				></h1>

				{result === 'sent' && (
					<div className="alert alert-success my-3" role="alert">
						<p className="h6">{t('t6')}</p>
						{t('t7')}
						<p>
							<button
								className="btn btn-primary text-light px-3 rounded-pill mt-3"
								onClick={newForm}
							>
								{t('t8')}
							</button>
						</p>
					</div>
				)}
				{result === 'error' && (
					<div className="alert alert-success my-3" role="alert">
						<p className="h6">{t('t9')}</p>
						{t('t10')}
					</div>
				)}

				{(result === 'booking' || result === 'new' || result === 'error') && (
					<>
						<div className="my-4">
							<p>{t('t11')}</p>
						</div>

						<Form
							initialValues={{
								agreement_accepted: '',
								citizenship: '',
								date_of_birth: '',
								email: '',
								firstname: '',
								lastname: '',
								location: '',
								passport_number: '',
								personal_number: '',
								phone_number: '',
								product: 'pcr',
								tracking: '',
								type: 'travel',
							}}
							onSubmit={handleSubmit}
							validationSchema={validationSchema}
						>
							<Select
								customOnChange={handleChangeLocation}
								id="location"
								name="location"
								options={locations}
								placeholder={t('t13')}
								required
							/>
							<Select
								id="product"
								name="product"
								options={products}
								placeholder={t('t12')}
								required
							/>
							<Select
								options={citizenships}
								name="citizenship"
								placeholder={t('t16')}
							/>
							<Input
								id="firstname"
								name="firstname"
								placeholder={t('t14')}
								required
								type="text"
							/>
							<Input
								id="lastname"
								name="lastname"
								placeholder={t('t15')}
								required
								type="text"
							/>
							<Input
								id="email"
								name="email"
								placeholder={t('t17')}
								required
								type="email"
							/>
							<PhoneNumber
								id="phone_number"
								name="phone_number"
								placeholder={t('t18')}
								required
							/>
							<Input
								id="personal_number"
								name="personal_number"
								placeholder={t('t19')}
								type="text"
							/>
							<Input
								id="date_of_birth"
								name="date_of_birth"
								placeholder={t('t20')}
								required
								type="text"
							/>
							<Input
								id="passport_number"
								name="passport_number"
								placeholder={t('t21')}
								required
								type="text"
							/>
							<span className={tracking ? '' : 'd-none'}>
								<Input
									class="d-none"
									id="tracking"
									name="tracking"
									placeholder={t('t25')}
									value={tracking}
									type="text"
								/>
							</span>
							<Checkbox id="agreement_accepted" name="agreement_accepted">
								{t('t24')}
							</Checkbox>
							<Submit name={t('t22')} />
						</Form>

						<Disclaimer />
					</>
				)}
			</div>
		</Framework>
	);
};

Register.propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	search: PropTypes.object,
};

export default withLocation(Register);

export const query = graphql`
	query {
		allLocation(filter: { active: { eq: true } }) {
			edges {
				node {
					id
					name
					partner {
						id
					}
					products {
						type
					}
				}
			}
		}

		allCitizenship(sort: { fields: name }) {
			edges {
				node {
					id
					name
					translation_key
				}
			}
		}

		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
