import { Form as FormikForm, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const Form = ({ children, initialValues, onSubmit, validationSchema }) => {
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
		>
			{() => <FormikForm>{children}</FormikForm>}
		</Formik>
	);
};

Form.propTypes = {
	children: PropTypes.array,
	initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
	validationSchema: PropTypes.object,
};

export default Form;
