import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const Input = ({ ...props }) => {
	const [field, meta] = useField(props);

	return (
		<div className="mb-3">
			<label className="form-label" htmlFor={props.id}>
				{props.placeholder}
			</label>
			<input
				className={`form-control ${
					meta.error && meta.touched ? 'is-invalid' : ''
				}`}
				{...field}
				{...props}
			/>
			{meta.error && meta.touched ? (
				<div className="invalid-feedback">{meta.error}</div>
			) : null}
		</div>
	);
};

Input.propTypes = {
	id: PropTypes.string,
	placeholder: PropTypes.string,
};

export default Input;
